import React from 'react';

import HubspotForm from '../Hubspot/hubspotForm.tsx';
import Logo from '../Logo';
import SignUpForm from '../SignUpForm';

import './SignUpPageView.scss';

const SignUpPageView = () => (
  <div className="SignUpPage">
    <div className="SignUpPage--form">
      <div className="SignUpPage--form--container">
        <h3 className="SignUpForm--title">
          <h2 style={{ marginBottom: '0.5em' }}>
            <Logo className="signup-invert" />
          </h2>
          {/* eslint-disable-next-line max-len */}
          We're always working on something new. Sign up for our
          newsletter to stay up to date on all the latest brand and
          product news!
        </h3>
        <HubspotForm
          id="general"
          formId="cbffbe5c-0b75-4629-9d1d-50ae4e621153"
        />
        <SignUpForm />
      </div>
      <div className="Header--rainbowStrip" />
    </div>
  </div>
);

export default SignUpPageView;
