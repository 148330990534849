import React from 'react';

import Layout from '../components/Layout';
import Seo from '../components/SEO';
import SignUpPageView from '../components/SignUpPageView/SignUpPageView';

export default class SignUpFormLayout extends React.PureComponent {
  render() {
    return (
      <Layout view="SignUpPage">
        <Seo title="Client Intake Form" />
        <SignUpPageView />
      </Layout>
    );
  }
}
